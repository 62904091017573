@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Castoro&family=Noto+Sans+JP&display=swap");
.access__img {
  display: block;
  margin: 60px auto 0;
}

.bannerArea {
  background-color: #F6F3ED;
  min-width: 1250px;
  margin-top: 80px;
}

@media (max-width: 767px) {
  .bannerArea {
    min-width: 100%;
  }
}

.bannerArea__inner {
  padding: 40px 0;
  width: 1080px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 767px) {
  .bannerArea__inner {
    width: 100%;
    padding: 16px 20px;
  }
}

.bannerArea .bannerBox {
  display: flex;
  justify-content: center;
}

.bannerArea .banner {
  width: 278px;
  display: inline-block;
  transition: opacity .2s linear;
}

.bannerArea .banner:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .bannerArea .banner {
    width: 50%;
  }
}

.bannerArea .banner + .banner {
  margin-left: 32px;
}

@media (max-width: 767px) {
  .bannerArea .banner + .banner {
    margin-left: 11px;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Castoro', 'Noto Sans JP', "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-size: 1.6rem;
  color: #463E36;
  background-repeat: no-repeat;
  background-position: top 0 left 0;
  padding-top: 100px;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    padding-top: 62px;
  }
}

body.topBg {
  background-image: url(/assets/img/top_bg.png);
}

@media (max-width: 767px) {
  body.topBg {
    background-image: url(/assets/img/top_bg_sp.png);
  }
}

body.pageBg {
  background-image: url(/assets/img/top_bg.png);
}

@media (max-width: 767px) {
  body.pageBg {
    background: none;
  }
}

h2, h3, h4, p, li {
  line-height: 1.75;
}

img {
  max-width: 100%;
}

@media (max-width: 767px) {
  .pc_only {
    display: none !important;
  }
}

.sp_only {
  display: none !important;
}

@media (max-width: 767px) {
  .sp_only {
    display: block !important;
  }
}

.wrapper {
  width: 1250px;
  padding: 0 85px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .wrapper {
    width: auto;
    padding: 0 20px;
  }
}

.h1Title {
  text-align: center;
  margin-top: 32px;
}

@media (max-width: 767px) {
  .h1Title {
    margin-top: 0;
    padding: 36px 20px 24px;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background-image: url(/assets/img/detail_bg_sp.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.h1Title__main {
  font-size: 5.6rem;
  color: #463E36;
}

@media (max-width: 767px) {
  .h1Title__main {
    font-size: 3.6rem;
  }
}

.h1Title__sub {
  font-size: 1.8rem;
  color: #8B6B53;
}

@media (max-width: 767px) {
  .h1Title__sub {
    font-size: 1.4rem;
  }
}

.h2TitleLeft {
  font-size: 2.4rem;
  color: #463E36;
  border-left: 4px solid #8B6B53;
  padding-left: 16px;
  margin-top: 60px;
  margin-bottom: 24px;
  font-weight: bold;
}

.h2TitleLeft.mt0 {
  margin-top: 0;
}

@media (max-width: 767px) {
  .h2TitleLeft.mt0 {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .h2TitleLeft {
    font-size: 2rem;
    margin-top: 50px;
  }
}

.linkButton {
  background-color: #fff;
  text-align: center;
  display: block;
  width: 278px;
  color: #8B6B53;
  border: 1px solid #8B6B53;
  padding: 15px 0;
  margin: 60px auto 0;
  position: relative;
}

.linkButton + .linkButton {
  margin-top: 16px;
}

.linkButton:hover {
  filter: brightness(97%);
}

@media (max-width: 767px) {
  .linkButton {
    margin: 40px auto 0;
    width: 246px;
  }
}

.linkButton::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  top: 18px;
}

.linkButton--dark {
  background-color: #8B6B53;
  color: #fff;
}

.linkButton--dark::after {
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
}

.linkButton--dark:hover {
  filter: brightness(115%);
}

.linkButton--form {
  margin-top: 16px;
}

.normalList__item {
  position: relative;
  padding-left: 15px;
}

.normalList__item + .normalList__item {
  margin-top: 12px;
}

.normalList__item::before {
  position: absolute;
  left: 0;
  top: 11px;
  content: "";
  background: #CCC8BD;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .normalList__item::before {
    top: 8px;
  }
}

.normalText + .normalText {
  margin-top: 30px;
}

.normalText em {
  color: #936372;
  font-weight: normal;
  font-style: normal;
}

.normalText b {
  font-weight: bold;
}

.normalText a {
  text-decoration: underline;
  color: #8B6B53;
}

.normalText a:hover {
  text-decoration: none;
}

.normalText--center {
  text-align: center;
}

.normalText--red {
  color: #AF2640;
}

.noteList {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .noteList {
    margin-top: 12px;
  }
}

.noteList__item {
  position: relative;
  font-size: 1.4rem;
  color: #8B8076;
  padding-left: 16px;
}

@media (max-width: 767px) {
  .noteList__item {
    font-size: 1.2rem;
  }
}

.noteList__item + .noteList__item {
  margin-top: 8px;
}

.noteList__item::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "※";
}

.breadcrumb {
  background-color: #ECE9E2;
  padding: 12px 16px;
  display: flex;
  margin-top: 26px;
}

@media (max-width: 767px) {
  .breadcrumb {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}

.breadcrumb a {
  text-decoration: underline;
  color: #8B6B53;
}

.breadcrumb a:hover {
  text-decoration: none;
}

.breadcrumb__item {
  color: #463E36;
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  .breadcrumb__item {
    font-size: 1.2rem;
  }
}

.breadcrumb__item:not(:last-child)::after {
  content: "";
  display: inline-block;
  border-top: solid 2px #707070;
  border-right: solid 2px #707070;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  margin-bottom: 1px;
  margin-left: 3px;
}

.breadcrumb__item + .breadcrumb__item {
  margin-left: 8px;
}

.contact {
  width: 803px;
  margin: 70px auto 0;
}

@media (max-width: 767px) {
  .contact {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .contact label, .contact input, .contact textarea, .contact select {
    font-size: 1.6rem;
  }
}

.contact label {
  display: inline-block;
}

.contact__item {
  margin-top: 50px;
  display: flex;
}

@media (max-width: 767px) {
  .contact__item {
    width: 100%;
    display: block;
    margin-top: 36px;
  }
}

.contact__title {
  font-size: 1.8rem;
  width: 246px;
  position: relative;
  padding: 4px 0;
}

@media (max-width: 767px) {
  .contact__title {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .contact__title p {
    padding-right: 50px;
  }
}

.contact dd {
  width: 524px;
  margin-left: 32px;
}

@media (max-width: 767px) {
  .contact dd {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
}

.contact__check {
  display: flex;
  flex-wrap: wrap;
}

.contact__check li {
  width: 50%;
}

.contact__check li:nth-child(n+3) {
  margin-top: 16px;
}

.contact__checkBox {
  display: none;
}

.contact__checkBox + label {
  padding-left: 30px;
  position: relative;
}

.contact__checkBox + label::before {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 3px;
  border: 2px solid #CCC8BD;
  border-radius: 3px;
}

.contact__checkBox:checked + label::before {
  background-color: #8B6B53;
  border: none;
}

.contact__checkBox:checked + label::after {
  content: "";
  background-image: url(/assets/img/mark.png);
  width: 13px;
  height: 10px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.contact__select {
  width: 100%;
  background: #fff;
  border: 1px solid #CCC8BD;
  padding: 10px 32px 10px 12px;
  border-radius: 3px;
  color: #8B8076;
}

.contact__selectWrap {
  position: relative;
}

.contact__selectWrap::after {
  content: "";
  border-bottom: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 14px;
  top: 11px;
}

.contact__text {
  width: 100%;
  border: 1px solid #CCC8BD;
  padding: 10px 12px;
  border-radius: 3px;
}

.contact__text:focus {
  border: 1px solid #8B6B53;
}

.contact__textWrap {
  position: relative;
  width: 100%;
}

.contact__textWrap--active::before {
  content: attr(title);
}

.contact__textWrap--focus::before {
  color: #8B6B53 !important;
}

.contact__textWrap::before {
  position: absolute;
  left: 9px;
  top: -5px;
  font-size: 1.2rem;
  color: #8B8076;
  background: #fff;
  padding: 0 3px;
}

.contact__textWrap + .contact__textWrap {
  margin-left: 12px;
}

.contact__text--small {
  width: 254px;
}

@media (max-width: 767px) {
  .contact__text--small {
    width: 100%;
  }
}

.contact__textarea {
  padding: 10px 12px;
  width: 100%;
  border: 1px solid #CCC8BD;
  height: 144px;
  border-radius: 3px;
}

.contact__textarea:focus {
  border: 1px solid #8B6B53;
}

.contact__radioWrap {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}

.contact__radioWrap li {
  width: 50%;
}

.contact__radioWrap li:nth-child(n+3) {
  margin-top: 16px;
}

.contact__radio {
  display: none;
}

.contact__radio + label {
  padding-left: 30px;
  position: relative;
}

.contact__radio + label::before {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 3px;
  border: 2px solid #CCC8BD;
  border-radius: 50%;
}

.contact__radio:checked + label::before {
  border: 2px solid #8B6B53;
}

.contact__radio:checked + label::after {
  content: "";
  position: absolute;
  background-color: #8B6B53;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 4px;
}

.contact__required {
  position: absolute;
  right: 0;
  top: 10px;
  font-weight: normal;
  border-radius: 3px;
  font-size: 1.1rem;
  color: #fff;
  display: inline-block;
  margin-left: auto;
  padding: 2px 8px;
  background-color: #936372;
}

.contact__nameWrap {
  display: flex;
}

.contact__introduction {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .contact__introduction {
    display: block;
  }
}

.contact__introductionRadio {
  display: flex;
}

@media (max-width: 767px) {
  .contact__introductionRadio {
    display: block;
  }
}

.contact__introductionRadio li + li {
  margin-left: 24px;
}

@media (max-width: 767px) {
  .contact__introductionRadio li + li {
    margin-left: 0;
    margin-top: 12px;
  }
}

.contact__introduction .contact__textWrap {
  margin-left: auto;
  width: 254px;
}

@media (max-width: 767px) {
  .contact__introduction .contact__textWrap {
    width: 90%;
    margin-top: 13px;
  }
}

.normalText + .contact__textWrap {
  margin-top: 17px;
}

.contact__textWrap + .normalText {
  margin-top: 24px;
}

.course__lead {
  text-align: center;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .course__lead {
    text-align: left;
  }
}

.course__section {
  margin-top: 80px;
}

.course__section + .course__section {
  margin-top: 70px;
}

@media (max-width: 767px) {
  .course__section + .course__section {
    margin-top: 70px;
  }
}

.courseCol {
  margin-top: 36px;
}

.courseCol--online {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .courseCol--online {
    margin-top: 50px;
  }
}

.courseCol__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.courseCol__item--block {
  display: block;
}

.courseCol__item--block .courseCol__textArea {
  width: 100%;
}

@media (max-width: 767px) {
  .courseCol__item {
    display: block;
  }
}

.courseCol__item + .courseCol__item {
  margin-top: 63px;
}

.courseCol__item:nth-child(even) .courseCol__img {
  order: 2;
}

.courseCol__img {
  width: calc(50% - 32px);
}

@media (max-width: 767px) {
  .courseCol__img {
    text-align: center;
    width: 100%;
  }
}

.courseCol__textArea {
  width: calc(50% - 32px);
}

@media (max-width: 767px) {
  .courseCol__textArea {
    margin-top: 30px;
    width: 100%;
  }
}

.courseCol__textArea .h2TitleLeft {
  margin-top: 0;
}

.profile__item {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .profile__item {
    display: block;
  }
}

.profile__item + .profile__item {
  margin-top: 72px;
}

@media (max-width: 767px) {
  .profile__item + .profile__item {
    margin-top: 50px;
  }
}

.profile__img {
  width: 361px;
}

.profile__img .imgShadow, .profile__img img {
  width: 100%;
}

@media (max-width: 767px) {
  .profile__img .imgShadow, .profile__img img {
    width: auto;
  }
}

@media (max-width: 767px) {
  .profile__img {
    width: auto;
    text-align: center;
  }
}

.profile__textArea {
  width: 700px;
  margin-left: 64px;
}

@media (max-width: 767px) {
  .profile__textArea {
    margin-top: 24px;
    width: 100%;
    margin-left: 0;
  }
}

.profile__name {
  margin-bottom: 24px;
}

.profile__nameMain {
  font-size: 2.4rem;
  color: #463E36;
}

@media (max-width: 767px) {
  .profile__nameMain {
    font-size: 2rem;
  }
}

.profile__nameSub {
  font-size: 1.4rem;
  color: #8B8076;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .profile__nameSub {
    font-size: 1.2rem;
  }
}

.imgShadow {
  box-shadow: 12px 8px 0px 0px #ECE9E2;
  display: inline-block;
}

.fee__section + .fee__section {
  margin-top: 70px;
}

@media (max-width: 767px) {
  .fee__section + .fee__section {
    margin-top: 50px;
  }
}

.fee__section:first-child {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .fee__section:first-child {
    margin-top: 38px;
  }
}

.feeTable {
  width: 100%;
  margin-top: 24px;
  line-height: 1.75;
}

@media (max-width: 767px) {
  .feeTable {
    margin-top: 16px;
    border-bottom: 1px solid #CCC8BD;
  }
}

.feeTable tr {
  border-bottom: 1px solid #CCC8BD;
}

@media (max-width: 767px) {
  .feeTable tr {
    border: none;
  }
}

.feeTable tr:first-child {
  border-top: 1px solid #CCC8BD;
}

@media (max-width: 767px) {
  .feeTable tr:first-child {
    border: none;
  }
}

.feeTable th, .feeTable td {
  padding: 16px 24px;
  width: 360px;
}

@media (max-width: 767px) {
  .feeTable th, .feeTable td {
    padding: 16px;
    width: 100%;
  }
}

.feeTable th {
  background-color: #F6F3ED;
}

@media (max-width: 767px) {
  .feeTable th {
    border-top: 1px solid #CCC8BD;
  }
}

@media (max-width: 767px) {
  .feeTable--pc {
    display: none;
  }
}

.feeTable--sp {
  display: none;
}

@media (max-width: 767px) {
  .feeTable--sp {
    display: table;
  }
}

.footer {
  min-width: 1250px;
  background-color: #463E36;
  color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .footer {
    min-width: 100%;
  }
}

.footer__inner {
  width: 1080px;
  margin: 0 auto;
  padding: 40px 0;
}

@media (max-width: 767px) {
  .footer__inner {
    padding: 30px 20px 60px;
    width: 100%;
  }
}

.footer__lead {
  margin-top: 20px;
}

.footer__copy {
  display: inline-block;
  margin-top: 24px;
  font-size: 1.2rem;
}

.header {
  display: flex;
  align-items: flex-start;
  min-width: 1250px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

@media (max-width: 767px) {
  .header {
    min-width: 100%;
    height: 62px;
    background: #fff;
  }
}

.header__logo {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 41px;
  transition: opacity .2s linear;
}

.header__logo:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .header__logo {
    max-width: 96px;
    position: static;
    margin: 9px 0 0 16px;
  }
}

.header__right {
  display: flex;
  margin-left: auto;
  height: 100%;
}

@media (max-width: 767px) {
  .header__right {
    display: none;
  }
}

.header__contact {
  font-size: 1.2rem;
  margin-left: 22px;
  background: #8B6B53;
  color: #fff;
  position: relative;
  width: 100px;
  text-align: center;
  transition: all 0.5s 0s ease;
}

.header__contact .headerLink__text {
  top: 20px;
}

.header__contact .headerLink__text--normal {
  transform: rotateX(0deg) translateZ(15px);
}

.header__contact .headerLink__text--hover {
  transform: rotateX(90deg) translateZ(15px);
}

.header__contact:hover .headerLink__text--normal {
  transform: rotateX(-90deg) translateZ(15px);
}

.header__contact:hover .headerLink__text--hover {
  transform: rotateX(0deg) translateZ(15px);
}

@media (max-width: 767px) {
  .header__contact {
    width: 100%;
  }
}

.header__contact::before {
  content: "";
  background-image: url(/assets/img/icn_mail.svg);
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
}

.header--scroll {
  background: #fff;
  box-shadow: 0 1px 2px 1px #ccc;
}

.header--scroll .header__logo {
  position: static;
  margin-top: 11px;
  margin-left: 41px;
}

@media (max-width: 767px) {
  .header--scroll .header__logo {
    margin: 9px 0 0 16px;
  }
}

.headerLink {
  display: flex;
  align-items: center;
}

.headerLink__item {
  line-height: 1.3;
  text-align: center;
  width: 120px;
  height: 100%;
}

.headerLink__item .headerLink__text--normal {
  transform: rotateX(0deg) translateZ(25px);
}

.headerLink__item .headerLink__text--hover {
  transform: rotateX(90deg) translateZ(25px);
}

.headerLink__item:hover .headerLink__text--normal {
  transform: rotateX(-90deg) translateZ(25px);
}

.headerLink__item:hover .headerLink__text--hover {
  transform: rotateX(0deg) translateZ(25px);
}

.headerLink__item--course {
  position: relative;
}

.headerLink__text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}

.headerLink__textWrap {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.headerMenu {
  background-color: #F6F3ED;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  padding: 62px 20px 120px;
}

.headerMenu__contact {
  color: #fff;
  display: block;
  background-color: #8B6B53;
  margin-top: 8px;
  width: 100%;
  padding: 10px 60px;
  font-size: 1.8rem;
  position: relative;
  text-align: center;
}

.headerMenu__contact span {
  font-size: 1.2rem;
}

.headerMenu__contact::before {
  content: "";
  background-image: url(/assets/img/icn_mail.svg);
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.headerMenu__contact::after {
  content: "";
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 19px;
  top: 24px;
}

.headerMenuButton {
  display: none;
  width: 62px;
  height: 62px;
  background-color: #8B6B53;
  margin-left: auto;
  padding: 22px 0;
  cursor: pointer;
  position: relative;
}

@media (max-width: 767px) {
  .headerMenuButton {
    display: block;
  }
}

.headerMenuButton.is-open span {
  margin-top: 0;
}

.headerMenuButton.is-open span:nth-child(1) {
  top: 30px;
  transform: rotate(-45deg);
}

.headerMenuButton.is-open span:nth-child(2) {
  display: none;
}

.headerMenuButton.is-open span:nth-child(3) {
  transform: rotate(45deg);
  bottom: 30px;
}

.headerMenuButton span {
  width: 26px;
  height: 2px;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 18px;
}

.headerMenuButton span:nth-child(1) {
  top: 22px;
}

.headerMenuButton span:nth-child(2) {
  top: 30px;
}

.headerMenuButton span:nth-child(3) {
  bottom: 22px;
}

.headerMenuList__title {
  font-size: 1.6rem;
}

.headerMenuList__subTitle {
  font-size: 1.2rem;
  color: #8B6B53;
  margin-left: 1em;
}

.headerMenuList__item {
  border-bottom: 1px solid #CCC8BD;
  position: relative;
}

.headerMenuList__item::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 3px;
  top: 28px;
}

.headerMenuList__item a {
  padding: 20px 0;
  display: block;
}

.headerMenuList__item--course {
  padding: 0;
}

.headerMenuList__item--course::after {
  content: none;
}

.headerMenuList__item--course > .headerMenuList__title {
  border-bottom: 1px solid #CCC8BD;
  padding: 20px 0;
}

.headerMenuList__item .headerMenuList__item:last-child {
  border-bottom: none;
}

.headerMenuList__item .headerMenuList .headerMenuList__title {
  font-size: 1.4rem;
  color: #8B8076;
}

.snsIcons {
  display: flex;
  margin-top: 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.snsIcons__item {
  width: 30px;
}

.snsIcons__item + .snsIcons__item {
  margin-left: 24px;
}

.courseList {
  position: relative;
  padding: 4px 15px;
  text-align: left;
}

.courseList::before {
  content: "";
  position: absolute;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #F6F3ED;
  border-left: 8px solid transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 22px -6px rgba(0, 0, 0, 0.6);
}

.courseList__item {
  position: relative;
  transition: opacity .2s linear;
}

.courseList__item:hover {
  opacity: .7;
}

.courseList__item:not(:last-child) {
  border-bottom: 1px solid #CCC8BD;
}

.courseList__item a {
  display: block;
  padding: 20px 20px 20px 0;
  font-size: 1.6rem;
}

.courseList__item span {
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 15px;
  color: #8B6B53;
}

.courseList__item::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 3px;
  top: 28px;
}

.courseListWrap {
  justify-content: flex-start;
  display: none;
  position: absolute;
  left: 50%;
  top: 75px;
  transform: translateX(-50%);
  background-color: #F6F3ED;
  width: 232px;
  box-shadow: 0px 0px 22px -6px rgba(0, 0, 0, 0.6);
}

.youtubeModal {
  display: none;
  max-width: 280px;
  width: 75%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 8px 20px;
  z-index: 200;
}

.youtubeModal__close {
  height: 30px;
  width: 30px;
  position: relative;
}

.youtubeModal__close::before, .youtubeModal__close::after {
  content: "";
  width: 26px;
  height: 2px;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 2px;
}

.youtubeModal__close::before {
  top: 14px;
  transform: rotate(-45deg);
}

.youtubeModal__close::after {
  transform: rotate(45deg);
  bottom: 14px;
}

.youtubeModal__closeWrap {
  top: -42px;
  right: 0;
  position: fixed;
}

.youtubeList__item {
  position: relative;
  transition: opacity .2s linear;
}

.youtubeList__item:hover {
  opacity: .7;
}

.youtubeList__item:not(:last-child) {
  border-bottom: 1px solid #CCC8BD;
}

.youtubeList__item::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 4px;
  top: 22px;
}

.youtubeList__item a {
  padding: 15px 20px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.youtubeList__item a span {
  font-size: 1.2rem;
  color: #8B6B53;
}

.overlay {
  opacity: .4;
  background: #463E36;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  opacity: .6;
}

.newsTitle {
  font-size: 2.8rem;
  font-weight: bold;
}

.newsCol {
  display: flex;
  margin-top: 60px;
}

@media (max-width: 767px) {
  .newsCol {
    margin-top: 40px;
    display: block;
  }
}

.news__main {
  width: 802px;
  margin-right: 32px;
}

@media (max-width: 767px) {
  .news__main {
    width: 100%;
  }
}

.news__aside {
  width: 246px;
}

@media (max-width: 767px) {
  .news__aside {
    width: 100%;
    margin-top: 60px;
  }
}

.news__aside .asideTitle {
  font-size: 2.8rem;
  color: #463E36;
}

@media (max-width: 767px) {
  .news__aside .asideTitle {
    font-size: 2.4rem;
  }
}

.newsList {
  margin-top: 24px;
  border-top: 1px solid #CCC8BD;
  border-bottom: 1px solid #CCC8BD;
}

.newsList__item {
  transition: opacity .2s linear;
  position: relative;
}

.newsList__item:hover {
  opacity: .7;
}

.newsList__item::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 4px;
  top: 41%;
}

.newsList__item:not(:last-child) {
  border-bottom: 1px solid #CCC8BD;
}

.newsList__item > a {
  padding: 20px 25px 20px 0;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .newsList__item > a {
    padding: 10px 25px 10px 0;
    flex-wrap: wrap;
  }
}

.newsList__item .news__date {
  width: 94px;
}

@media (max-width: 767px) {
  .newsList__item .news__date {
    height: 19px;
    width: auto;
  }
}

.newsList__item .news__tagWrap {
  width: 90px;
}

@media (max-width: 767px) {
  .newsList__item .news__tagWrap {
    margin: 0 0 0 10px;
  }
}

.newsList__item .news__lead {
  width: 532px;
  margin-left: 32px;
}

@media (max-width: 767px) {
  .newsList__item .news__lead {
    margin-top: 8px;
    width: 100%;
    margin-left: 0;
  }
}

.asideSection + .asideSection {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .asideSection + .asideSection {
    margin-top: 40px;
  }
}

.asideNewsLink {
  margin-top: 16px;
}

.asideNewsLink__item {
  border-bottom: 1px solid #CCC8BD;
  transition: opacity .2s linear;
}

.asideNewsLink__item:hover {
  opacity: .7;
}

.asideNewsLink__item + .asideNewsLink__item {
  margin-top: 12px;
}

.asideNewsLink__title {
  font-size: 1.4rem;
}

.asideNewsLink__date {
  font-size: 1.4rem;
  color: #8B6B53;
  margin-top: 8px;
}

@media (max-width: 767px) {
  .asideNewsLink__date {
    font-size: 1.2rem;
  }
}

.asideCategory {
  margin-top: 16px;
}

.asideCategory__item {
  font-size: 1.4rem;
}

.asideCategory__item + .asideCategory__item {
  margin-top: 12px;
}

.asideCategory__item::before {
  margin-right: 8px;
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #CCC8BD;
}

.asideCategory__item a:hover {
  text-decoration: underline;
}

.articleTab {
  display: flex;
  width: 252px;
  margin: 16px auto 0;
  align-items: center;
}

@media (max-width: 767px) {
  .articleTab {
    width: 100%;
  }
}

.articleTab button {
  width: 12px;
}

.articleTabList {
  display: flex;
  margin: 0 40px;
}

@media (max-width: 767px) {
  .articleTabList {
    margin: 0 auto;
  }
}

.articleTabList__item {
  color: #8B6B53;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.articleTabList__item + .articleTabList__item {
  margin-left: 20px;
}

.articleTabList__item--active {
  background-color: #8B6B53;
  color: #fff;
}

.news__infoBlock {
  display: flex;
  margin-top: 16px;
  margin: 16px 0 36px;
  padding-bottom: 24px;
  border-bottom: 1px solid #CCC8BD;
}

.news__infoBlock .news__date {
  font-size: 2rem;
}

.news__infoBlock .news__tagWrap {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .news__infoBlock .news__tagWrap {
    margin-top: 0;
  }
}

.newsText + .newsText {
  margin-top: 30px;
}

.newsText h2 {
  font-size: 2.4rem;
  color: #463E36;
  border-left: 4px solid #8B6B53;
  padding-left: 16px;
  margin-top: 60px;
  margin-bottom: 24px;
  font-weight: bold;
}

.newsText em {
  color: #936372;
  font-weight: normal;
  font-style: normal;
}

.newsText b {
  font-weight: bold;
}

.newsText a {
  text-decoration: underline;
  color: #8B6B53;
}

.newsText a:hover {
  text-decoration: none;
}

.news__img {
  display: block;
  margin: 60px auto 0;
}

@media (max-width: 767px) {
  .news__img {
    margin: 50px auto 0;
  }
}

.section__kv {
  margin-top: 36px;
}

.section__kv img {
  width: 100%;
}

@media (max-width: 767px) {
  .section__kv {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}

.h2Section + .h2Section {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .h2Section + .h2Section {
    margin-top: 50px;
  }
}

.snsLink {
  position: fixed;
  bottom: 40px;
  left: 42px;
}

@media (max-width: 767px) {
  .snsLink {
    display: none;
  }
}

.snsLink__title {
  margin: 0 auto;
  writing-mode: vertical-rl;
  font-size: 1.2rem;
  color: #8B8076;
}

.snsLink__title.is-titleHidden {
  visibility: hidden;
  animation-duration: 0.2s;
  animation-name: fade-out;
}

.snsLink__title.is-titleVisible {
  visibility: visible;
  animation-duration: 0.2s;
  animation-name: fade-in;
}

@keyframes fade-out {
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 0.5;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 0.5;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .snsList {
    display: flex;
    margin-top: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.snsList__item {
  margin-top: 24px;
  width: 30px;
  transition: all .2s linear;
}

.snsList__item:hover {
  transform: scale(1.3);
}

@media (max-width: 767px) {
  .snsList__item {
    margin-top: 0;
    width: 30px;
  }
  .snsList__item + .snsList__item {
    margin-left: 24px;
  }
}

.snsList__item--youtube {
  cursor: pointer;
}

.topButtonArea {
  min-width: 1250px;
  background-color: #E2DFD9;
  text-align: center;
  height: 56px;
  padding-top: 24px;
  cursor: pointer;
}

.topButtonArea:hover .topButton {
  transform: rotate(45deg) scale(1.3);
}

@media (max-width: 767px) {
  .topButtonArea {
    min-width: 100%;
  }
}

.topButtonArea__inner {
  width: 1080px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .topButtonArea__inner {
    width: 100%;
  }
}

.topButtonArea .topButton {
  transition: all .2s linear;
  display: inline-block;
  border-top: solid 2px #8B8076;
  border-left: solid 2px #8B8076;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}

.tp-course {
  display: flex;
  margin-top: 36px;
}

@media (max-width: 767px) {
  .tp-course {
    display: block;
  }
}

.tp-course__item {
  display: block;
  width: calc(100% / 4);
  background-color: #fff;
  text-align: center;
  padding: 30px 20px 50px 20px;
  position: relative;
  transition: opacity .2s linear;
}

.tp-course__item:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .tp-course__item {
    margin: 0 9px;
    width: 100%;
  }
}

.tp-course__item:focus {
  outline: none;
}

.tp-course__item + .tp-course__item {
  margin-left: 32px;
}

@media (max-width: 767px) {
  .tp-course__item + .tp-course__item {
    margin-left: 9px;
  }
}

.tp-course__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 16px;
}

.tp-course__titleSub {
  display: block;
  color: #8B6B53;
  font-size: 1.4rem;
  font-weight: normal;
}

.tp-course__icon {
  margin: 0 auto;
}

.tp-course__lead {
  margin-top: 20px;
}

.tp-course__more {
  color: #8B6B53;
  font-size: 1.4rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.tp-course__more::after {
  content: "";
  display: inline-block;
  border-top: solid 1px #8B6B53;
  border-right: solid 1px #8B6B53;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  margin-bottom: 1px;
  margin-left: 3px;
}

.tp-courseBg {
  background-image: url(/assets/img/forte.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right 0 top 0;
  padding: 50px 0 60px;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slickControl {
  display: none;
  width: 142px;
  height: 20px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .slickControl {
    display: block;
    position: relative;
  }
}

.slickControl .slide-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.slickControl .prev-arrow {
  left: 0;
}

.slickControl .next-arrow {
  right: 0;
}

.slickControl .slide-dots {
  display: flex;
  justify-content: center;
}

.slickControl .slide-dots li + li {
  margin-left: 10px;
}

.slickControl .slide-dots li.slick-active button::after {
  background-color: #8B6B53;
}

.slickControl .slide-dots li button {
  position: relative;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
}

.slickControl .slide-dots li button::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #CCC8BD;
  position: absolute;
  top: 0;
  left: 0;
}

.hero {
  position: relative;
  min-height: 620px;
  margin: 0 auto;
  width: 1080px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .hero {
    display: block;
    width: 100%;
    padding-left: 20px;
    min-height: auto;
  }
}

.hero__inner {
  z-index: 2;
  position: relative;
}

@media (max-width: 767px) {
  .hero__inner {
    padding-right: 20px;
    transform: translateY(-60px);
    margin-bottom: -60px;
  }
}

.hero__titleSub {
  font-size: 2.4rem;
  background-color: #8B6B53;
  display: inline-block;
  color: #fff;
  padding: 6.5px 12px;
}

@media (max-width: 767px) {
  .hero__titleSub {
    font-size: 1.6rem;
    width: auto;
    padding: 5px 21px 5px 8px;
  }
}

.hero__titleMain {
  background-color: #fff;
  color: #463E36;
  font-size: 6.6rem;
  display: inline-block;
  margin-top: 8px;
  padding: 5.5px 0 5.5px 12px;
}

@media (max-width: 767px) {
  .hero__titleMain {
    font-size: 3.2rem;
    padding: 5.5px 36px 5.5px 12px;
  }
}

.hero__lead {
  margin-top: 20px;
}

.hero__lead span {
  background-color: #EAE3D3;
  display: inline-block;
  padding-left: 12px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .hero__lead span {
    background: none;
  }
}

.hero__kv {
  position: absolute;
  right: -142px;
  top: 0;
  z-index: 1;
  height: 620px;
  width: 900px;
}

.hero__kvWrap {
  position: relative;
  height: auto;
}

.hero__kvWrap img {
  position: absolute;
}

@media (max-width: 767px) {
  .hero__kv {
    width: 100%;
    position: static;
  }
}

@media (max-width: 767px) {
  .hero__lesson {
    margin: 0 auto;
    display: block;
  }
}

.hero__lessonWrap {
  width: 180px;
  height: 180px;
  position: absolute;
  bottom: -13px;
  right: 0;
  z-index: 2;
  display: block;
}

@media (min-width: 768px) {
  .hero__lessonWrap {
    background-image: url(/assets/img/free_lesson_pc.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .hero__lessonWrap:hover {
    background-image: url(/assets/img/free_lesson_pc_on.png);
  }
  .hero__lessonWrap::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .hero__lessonWrap:hover::before {
    animation: circle .75s;
  }
}

@media (max-width: 767px) {
  .hero__lessonWrap {
    width: 100%;
    margin-top: 36px;
    padding-right: 20px;
    position: static;
  }
}

@media (min-width: 1368px) {
  .hero__lessonWrap {
    right: -100px;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.info {
  display: flex;
  margin-top: 36px;
}

@media (max-width: 767px) {
  .info {
    display: block;
  }
}

.info__item {
  box-shadow: 0px 0px 25px -13px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: 50%;
  display: flex;
  transition: opacity .2s linear;
}

.info__item:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .info__item {
    width: 100%;
    align-items: flex-start;
  }
}

.info__item + .info__item {
  margin-left: 32px;
}

@media (max-width: 767px) {
  .info__item + .info__item {
    margin-left: 0;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .info__thum {
    width: 42%;
  }
}

.info__leadArea {
  padding: 24px 20px 50px 24px;
  width: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .info__leadArea {
    padding: 12px 16px 33px 16px;
  }
}

.info__date {
  font-size: 1.4rem;
  color: #8B6B53;
  position: absolute;
  bottom: 24px;
}

@media (max-width: 767px) {
  .info__date {
    font-size: 1.2rem;
    bottom: 12px;
  }
}

.news {
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
}

.news__item {
  width: calc(25% - 24px);
  position: relative;
  padding-bottom: 47px;
  transition: opacity .2s linear;
}

.news__item:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .news__item {
    width: calc(50% - 9px);
    margin-bottom: 36px;
    padding-bottom: 61px;
  }
  .news__item:nth-child(odd) {
    margin-right: 18px;
  }
}

.news__item + .news__item {
  margin-left: 32px;
}

@media (max-width: 767px) {
  .news__item + .news__item {
    margin-left: 0;
  }
}

.news__thum {
  width: 100%;
}

.news__info {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .news__info {
    display: block;
  }
}

.tp-section--news .news__lead {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .tp-section--news .news__lead {
    margin-top: 12px;
  }
}

.news__date {
  color: #8B6B53;
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  .news__date {
    font-size: 1.2rem;
  }
}

.news__tag {
  display: inline-block;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 3px;
  padding: 0 8px;
  line-height: 1.75;
}

.news__tag--violin {
  background-color: #8D936D;
}

.news__tag--fuga {
  background-color: #8B6B53;
}

.news__tag--piano {
  background-color: #936372;
}

.news__tag--compose {
  background-color: #B9984D;
}

.news__tag--other {
  background-color: #8B8076;
}

@media (max-width: 767px) {
  .news__tagWrap {
    margin-top: 8px;
  }
}

.newsMore {
  border: 1px solid #8B8076;
  width: 278px;
  color: #8B6B53;
  display: inline-block;
  padding: 14px 0;
  position: relative;
  transition: opacity .2s linear;
}

.newsMore:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  .newsMore {
    font-size: 1.6rem;
  }
}

.newsMore::after {
  content: "";
  border-top: solid 2px #8B6B53;
  border-right: solid 2px #8B6B53;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 19px;
  top: 17px;
}

.newsMore__area {
  text-align: center;
  margin-top: 67px;
}

@media (max-width: 767px) {
  .newsMore__area {
    margin-top: 0;
  }
}

.topBanner {
  background-color: #E2DFD9;
  text-align: center;
  padding: 40px 0;
  min-width: 1100px;
}

@media (max-width: 767px) {
  .topBanner {
    min-width: 100%;
  }
}

.topBanner a {
  transition: opacity .2s linear;
}

.topBanner a:hover {
  opacity: .7;
}

.topBanner img {
  width: 50%;
}

@media (max-width: 767px) {
  .topBanner img {
    width: 100%;
  }
}

.topBanner__inner {
  width: 1080px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .topBanner__inner {
    padding: 0 20px;
    width: 100%;
  }
}

.tp-main {
  overflow-x: hidden;
  min-width: 1250px;
}

@media (max-width: 767px) {
  .tp-main {
    min-width: 100%;
  }
}

.tp-section {
  min-width: 1100px;
}

@media (max-width: 767px) {
  .tp-section {
    min-width: 100%;
  }
}

.tp-section--info {
  margin-top: 107px;
}

@media (max-width: 767px) {
  .tp-section--info {
    margin-top: 40px;
  }
}

.tp-section--course {
  margin-top: 55px;
  background-color: #F6F3ED;
}

@media (max-width: 767px) {
  .tp-section--course {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  .tp-section--course .tp-section__inner {
    padding: 0;
  }
}

.tp-section--news {
  padding: 50px 0 0;
}

.tp-section__inner {
  width: 1080px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .tp-section__inner {
    width: 100%;
    padding: 0 20px;
  }
}

.h2Title {
  text-align: center;
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .h2Title {
    margin-bottom: 16px;
  }
}

.h2Title__main {
  font-size: 4.2rem;
  color: #463E36;
}

@media (max-width: 767px) {
  .h2Title__main {
    font-size: 2.8rem;
  }
}

.h2Title__sub {
  color: #8B6B53;
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  .h2Title__sub {
    font-size: 1.2rem;
  }
}
